import React, { Component } from 'react';
import { css } from '@emotion/core';
import axios from 'axios';
import * as qs from 'query-string';

export default class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.domRef = React.createRef();
    this.state = { feedbackMsg: null };
  }

  handleSubmit(event) {
    // Do not submit form via HTTP, since we're doing that via XHR request.
    event.preventDefault();
    // Loop through this component's refs (the fields) and add them to the
    // formData object. What we're left with is an object of key-value pairs
    // that represent the form data we want to send to Netlify.
    const formData = {};
    Object.keys(this.refs).map((key) => (formData[key] = this.refs[key].value));

    // Set options for axios. The URL we're submitting to
    // (this.props.location.pathname) is the current page.
    const axiosOptions = {
      url: this.props.location,
      method: 'post',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(formData),
    };

    // Submit to Netlify. Upon success, set the feedback message and clear all
    // the fields within the form. Upon failure, keep the fields as they are,
    // but set the feedback message to show the error state.
    axios(axiosOptions)
      .then((response) => {
        this.setState({
          feedbackMsg: "Message sent successfully. We'll get back to you soon!",
        });
        this.domRef.current.reset();
      })
      .catch((err) =>
        this.setState({
          feedbackMsg: 'Form could not be submitted. Please try again.',
        })
      );
  }

  render() {
    const contactFormStyles = css`
      max-width: 600px;
      margin: 0 var(--size-4);

      @media (min-width: 648px) {
        margin: 0 auto;
      }

      p {
        margin: 0 0 var(--size-6) 0;
      }

      input,
      textarea {
        width: 100%;
        background: #fafafa;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: var(--size-2);
        padding: var(--size-2);
      }
      input {
        height: var(--size-8);
      }
      textarea {
      }
      button {
        width: 100%;
        height: 48px;
        font-size: var(--size-2);
        font-weight: 600;
        color: var(--colour-white);
        background: #0c6aad;
        border: none;
        border-radius: 4px;
        cursor: pointer;
      }
    `;

    const contactTitleStyles = css`
      /* font-size: var(--size-7); */
    `;

    const formLabelStyle = css`
      font-size: var(--size-2);
      font-weight: 600;
      display: block;
    `;

    return (
      <div css={contactFormStyles}>
        <h2 css={contactTitleStyles}>Get in touch</h2>

        {this.state.feedbackMsg && <p>{this.state.feedbackMsg}</p>}

        <form
          ref={this.domRef}
          name='contact'
          method='POST'
          data-netlify='true'
          onSubmit={(event) => this.handleSubmit(event)}
        >
          <input
            ref='form-name'
            type='hidden'
            name='form-name'
            value='contact'
          />
          <p>
            <label>
              <span css={formLabelStyle}>Name</span>
              <input ref='name' type='text' name='name' />
            </label>
          </p>
          <p>
            <label>
              <span css={formLabelStyle}>Email</span>{' '}
              <input ref='email' type='email' name='email' />
            </label>
          </p>
          <p>
            <label>
              <span css={formLabelStyle}>Message</span>
              <textarea ref='message' rows='8' name='message'></textarea>
            </label>
          </p>
          <p>
            <button type='submit'>Send</button>
          </p>
        </form>
      </div>
    );
  }
}
